import './App.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import FanMomentumlogo1 from ".//images/FanMomentumlogo1.png";
import Fantitle from ".//images/Fantitle.png";
import fanmomentumgradient from ".//images/fanmomentumgradient.png";
import momentumtitle from ".//images/momentumtitle.png";
import mobileappbackground from ".//images/mobileappbackground.png";
import mobilebackgroundsnip from ".//images/mobilebackgroundsnip.png";
import bluepurplegradientsnip from ".//images/bluepurplegradientsnip.png";
import fanmomentumlogo2 from ".//images/fanmomentumlogo2.png";
import redbluegradient from ".//images/redbluegradient.png";
import investbffd from ".//images/investbffd.png";
import tradebffd from ".//images/tradebffd.png";
import investgradient from ".//images/investgradient.png";
import tradegradient from ".//images/tradegradient.png";
import earngradient from ".//images/earngradient.png";
import tombradycard from ".//images/tombradycard.png";
import lebronjamescard from ".//images/lebronjamescard.png";
import lebronjamescardsnip2 from ".//images/lebronjamescardsnip.png";
import patrickmahomescard from ".//images/patrickmahomescard.png";
import patrickmahomescardsnip2 from ".//images/patrickmahomessnip2.png";
import stephencurrycard from ".//images/stephencurrycard.png";
import blackbackground from ".//images/blackbackground.png";
import burrowpricetransparent from ".//images/burrowpricetransparent.png";
import barkleypricetransparent from ".//images/barkleypricetransparent.png";
import durantpricetransparent from ".//images/durantpricetransparent.png";
import doncicpricetransparent from ".//images/doncicpricetransparent.png";
import fmappscreen1 from ".//images/fmappscreen1.png";
import fmappscreen2 from ".//images/fmappscreen2.png";
import fmappscreen3 from ".//images/fmappscreen3.png";
import leaguelogos from ".//images/leaguelogos.png";
import fmappiphone5 from ".//images/fmappiphone5.png"
import fmappiphone2 from ".//images/fmappiphone2.png"
import fmappiphone1 from ".//images/fmappiphone1.png"
import fmappiphone6 from ".//images/fmappiphone6.png"
import fmappiphone31 from ".//images/fmappiphone3.1.png"
import fmappiphone4 from ".//images/fmappiphone4.png"
import fanmomentumgif from ".//images/fanmomentumgif.gif"
















import ".//style.css"

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyCmDMmJsCS75DlYdhAGteQY7BkeyEx7D-M",
    authDomain: "fanmomentum.firebaseapp.com",
    projectId: "fanmomentum",
    storageBucket: "fanmomentum.appspot.com",
    messagingSenderId: "395852327923",
    appId: "1:395852327923:web:4acd5ef6ab71cd87588086",
    measurementId: "G-CGB9G3YS2J"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return (

  <div>


    <div className="backcolor"   
    >

          

             <img  className="logo"
                src={FanMomentumlogo1}
              />


              <img className="fanmomentumgradient"
                src={fanmomentumgradient}
              />


              <img className="fanmomentumgif"
                src={fanmomentumgif}
              />

              <h1 className="hometitle">The Sports Stock Market</h1>

              <h1 className="hometitle2">Trade Athletes Like Stocks</h1>

              

              <div class="parent1">
         <img className="image1"  src={bluepurplegradientsnip} />
         <img className="image2" src={fmappiphone31} />
         <img className="image3" src={fmappiphone4} />
             </div>

              <img  className="logo2"
                src={FanMomentumlogo1}
              />

               <img  className="invest"
                src={investgradient}
              />

              
              <h1 className="investdescription">
                FanMomentum is a sports stock market trading platform that 
                allows you to make money by investing in the career 
                performance of your favorite athletes like you would a 
                company on the stock market and generate profits overtime in your portfolio 
                based on the career success of the athletes that you have invested in
              </h1>

              <img  className="logo3"
                src={FanMomentumlogo1}
              />

            <img  className="trade"
                src={tradegradient}
              />

            <h1 className="tradedescription">
               You can buy and sell shares of your favorite 
               athletes using real money just like the stock market
               or a cryptocurrency exchange
              </h1>

              <div class="parent2">
         <img className="image1trade"  src={bluepurplegradientsnip} />
         <img className="image2trade" src={burrowpricetransparent} />
         <img className="image3trade" src={barkleypricetransparent} />
         <img className="image4trade" src={durantpricetransparent} />
         <img className="image5trade" src={doncicpricetransparent} />
             </div>

          
             <div class="parent3">
         <img className="image1earn"  src={bluepurplegradientsnip} />
         <img className="image2earn" src={fmappiphone5} />
         <img className="image3earn" src={fmappiphone2} />
         <img className="image4earn" src={fmappiphone6} />
             </div>

             <img  className="logo4"
                src={FanMomentumlogo1}
              />


              <img  className="earn"
                src={earngradient}
              />

               <h1 className="earndescription">
                 Track and watch your portfolio grow over time in your
                 very own dashboard, where you can look at the athletes
                 you own and the amount of shares you have in each one.
                 Buy and sell your shares whenever you want
               </h1>

             

               <img  className="leaguelogos"
                src={leaguelogos}
              />

      
    </div>

    </div>
  );
}

export default App;
